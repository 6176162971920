#pageWrap {
	background: $light;
	max-width: rem(1604px);
	margin: 0 auto;

}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top: rem(184px);
	// overflow: hidden;

	@include breakpoint(large) {
		margin-top: rem(195px);
	}

	@include breakpoint(giant) {
		margin-top: rem(195px);
	}

	@include breakpoint(huge) {
		margin-top: rem(199px);
	}

	// @include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);

	// &.lazyLoaded {
	// 	background-size:cover
	// }

	.my-slider-header {
		height: 12rem;

		@include breakpoint(medium) {
			height: 14rem;
		}

		@include breakpoint(large) {
			height: 24rem;
		}

		div {
			height: 100%;

			.claim {
				display: flex;
				align-items: center;
				padding: 0 1rem;

				@include breakpoint(small) {
					padding: 0 2rem;
				}

				@include breakpoint(medium) {
					padding: 0 1rem;
					max-width: rem(1380px);
					margin: 0 auto;
				}

				p {
					color: $light;
					font-weight: 900;
					font-style: italic;
					font-size: rem(22px);

					@include breakpoint(tiny) {
						font-size: rem(24px);
						line-height: 1.4em;
					}

					@include breakpoint(small) {
						font-size: rem(26px);
					}

					@include breakpoint(medium) {
						font-size: rem(30px);
					}

					@include breakpoint(large) {
						font-size: rem(34px);
					}

					@include breakpoint(giant) {
						font-size: rem(36px);
					}

					br {
						@include breakpoint(small, max) {
							display: none;
						}
					}
				}
			}
		}

		.first {
			background: url(/images/layout/tiny-slider_1.jpg) no-repeat center / cover;

			@include breakpoint(medium) {
				background: url(/images/layout/medium-slider_1.jpg) no-repeat center / cover;
			}

			@include breakpoint(large) {
				background: url(/images/layout/slider_1.jpg) no-repeat center / cover;
			}

		}

		.second {
			background: url(/images/layout/tiny-slider_1.jpg) no-repeat center / cover;

			@include breakpoint(medium) {
				background: url(/images/layout/medium-slider_1.jpg) no-repeat center / cover;
			}

			@include breakpoint(large) {
				background: url(/images/layout/slider_1.jpg) no-repeat center / cover;
			}

		}
	}
}

.wrapper {
	width: 100%;
	height: 57vw;
	overflow: hidden;
	opacity: 1;
	z-index: 1;
	position: relative;

	@include breakpoint(large) {
		height: 48rem;
	}

	@include breakpoint(huge) {
		height: 56rem;
	}

	.wrapper__video {
		object-fit: cover;
		width: 100vw;
		height: auto;
		min-width: 100vw;
		min-height: 16vh;

		@include breakpoint(large) {
			height: 48rem;
		}

		@include breakpoint(huge) {
			height: 56rem;
			width: 100%;
			min-width: 100%;
		}

	}
}

.brandBack {
	background: $light;
	padding: 1.5rem 1rem;
	position: absolute;
	width: 100%;
	top: -124px;
	border-bottom: 1px solid $primary;
	z-index: 11;

	@include breakpoint(large) {
		display: none;
	}

	&:after {
		content: '';
		height: 55px;
		width: 61px;
		background: url(/images/layout/g_brand.png) no-repeat center;
		position: absolute;
		right: 1rem;
		z-index: 12;
		top: 50%;
		transform: translateY(-50%);
	}

}

.branding {
	display: block;
	max-width: 184px;
	width: 100%;
	height: auto;
	margin: 0;
	position: relative;
	z-index: 1;

	@include breakpoint(large) {
		margin: 2.3rem 0;
		float: left;
		width: 8rem;
		margin-right: 2rem;
	}

	@include breakpoint(giant) {
		margin: 2.3rem 2rem 2.3rem 0;
	}

	@include breakpoint(huge) {
		width: auto;
		margin: 2rem 4rem 2rem 0;
	}

	&.largeBlock {
		@include breakpoint(large, max) {
			display: none;
		}
	}
}

picture {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
	width: 100%;
	height: auto;
}

.marginSide {

	@include breakpoint(huge) {
		margin: 0 1rem;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	max-width: 1380px;
	margin: 0 auto;


	&#content {
		padding: 3rem 0;
		border-top: 1px solid $border;
		overflow: hidden;


		@include breakpoint(small) {
			padding: 4rem 0;
		}

		@include breakpoint(giant) {
			padding: 5rem 0;
		}

		h1 {
			br {
				display: none;
			}
		}


		.newsDiv {
			padding: 4rem 0 0;
			margin: 0 1rem;

			@include breakpoint(small) {
				padding: 5rem 0 0;
			}

			@include breakpoint(medium) {
				margin: 0 2rem;
			}

			@include breakpoint(large) {
				padding: 5rem 1rem 0;
			}

			@include breakpoint(giant) {
				padding: 6rem 0 0;
				margin: 0 2rem 0 1rem;
			}

			@include breakpoint(huge) {
				max-width: rem(1380px);
				margin: 0 1.75rem 0 1rem;
			}

			.text {
				background: $primary;
				padding: 4rem 1rem;
				color: $light;
				box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);


				@include breakpoint(small) {
					padding: 4rem 2rem 4rem 2rem;
				}

				@include breakpoint(medium) {
					padding: 4rem 3rem 4rem 3rem;
				}

				@include breakpoint(large) {
					box-shadow: none;
				}

				.h2 {
					color: $light;

					span {
						color: $extra;
					}
				}
			}

			.row {

				@include breakpoint(medium) {
					align-items: stretch;
				}

			}

			.box {
				padding: 4rem 1rem;
				box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);
				margin: 4rem 0;

				@include breakpoint(small) {
					padding: 4rem 2rem 3rem 2rem;
				}

				@include breakpoint(medium) {
					padding: 4rem 3rem 3rem 3rem;
				}

				@include breakpoint(large) {
					padding: 5rem 3rem 4rem;
					margin: 5rem 1rem 5rem 0;
				}

				@include breakpoint(giant) {
					margin: 0;
				}

				&.noTop {
					margin-top: 0;

					@include breakpoint(large) {
						margin: 5rem 0 5rem 1rem;
					}

					@include breakpoint(giant) {
						margin: 0 0 0 1.4rem
					}

				}

				img {
					width: auto;
				}
			}

			.feedBox {

				.imgTop.col {
					padding: 0;
					margin: 0;


				}

				@include breakpoint(large) {
					box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);
				}

				@include breakpoint(giant) {
					background: $primary;
				}

			}

			.pic {
				padding: 0;
				position: relative;
				height: 12rem;
				overflow: hidden;

				@include breakpoint(small) {
					height: 16rem;
				}

				@include breakpoint(large) {
					height: 14rem;
				}

				img {
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					width: 100%;
					min-width: auto;
					max-width: none;
					height: auto;

					@include breakpoint(medium) {
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}

					@include breakpoint(tiny) {
						height: auto;
						min-width: 100%;
					}
				}
			}

			.middleBox {
				padding: 0;

				@include breakpoint(large) {
					display: flex;
					justify-content: space-around;
					padding: 0 1rem;
				}

				@include breakpoint(giant) {
					padding: 0 0 0 2rem;
				}

			}
		}

		.firstSection {
			padding: 4rem 0 2.25rem;
			box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);


			@include breakpoint(small) {
				padding: 5rem 1rem 3.25rem;
			}

			@include breakpoint(medium) {
				margin: 0 2rem;
				padding: 5rem 2rem 3.25rem;
			}

			@include breakpoint(large) {
				margin: 0 3rem;
			}

			@include breakpoint(giant) {
				margin: 6rem 1rem;
			}

			@include breakpoint(huge) {
				padding: 5rem 2rem 3.25rem;
				margin: 6rem auto;
				max-width: rem(1348px);
			}

		}

		.secondSection {
			text-align: left;
			box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2);
			margin: 4rem 0;

			@include breakpoint(medium) {
				margin: 4rem 2rem;
			}

			@include breakpoint(large) {
				margin: 5rem 3rem;
				position: relative;

				&:before {
					content: '';
					height: 70px;
					width: 84px;
					background: url(/images/index/mouse.png) no-repeat center;
					position: absolute;
					left: 20%;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 1;
					transition: 400ms all ease;
					opacity: 0.5;
					animation-name: mouse;
					animation-duration: 2s;
					animation-iteration-count: infinite;
				}

				// &:hover{
				// 	&:before{
				// 		opacity: 0;
				// 	}
				// }
			}

			@include breakpoint(giant) {
				margin: 6rem 1rem;

				&:before {
					left: 50%;
					top: 3rem;
					transform: translateX(-50%);
					animation-name: mouse2;
				}
			}

			@include breakpoint(huge) {
				margin: 0 auto;
				margin-bottom: 6rem;
				max-width: rem(1348px);

				&:before {
					animation-name: mouse3;
				}
			}


			.text {
				padding: 4rem 1rem;

				@include breakpoint(small) {
					padding: 5rem 2rem;
				}

				@include breakpoint(medium) {
					padding: 5rem 3rem 0 3rem;
				}

				@include breakpoint(giant) {
					padding: 0 2rem 0 3rem;
				}

				@include breakpoint(giant) {
					border-right: 1px solid $border;
				}

				.h1 {

					span {
						font-size: rem(18px);
					}

					@include breakpoint(large) {
						font-size: rem(24px);
					}

					@include breakpoint(giant) {
						font-size: rem(28px);
					}

					@include breakpoint(huge) {
						font-size: rem(36px);
					}

					br {
						@include breakpoint(huge, max) {
							display: none;
						}
					}
				}

				.btn {

					@include breakpoint(large) {
						padding: 0.8rem 1.5rem;
					}

				}

				.branding {
					margin: 0 0;
					width: auto;
					float: none;
				}
			}

			.pic {
				padding: 0 0 2rem 0;
				text-align: right;
				position: relative;

				@include breakpoint(medium, max) {
					display: none;
				}

				@include breakpoint(giant) {
					padding: 0;
					max-height: 760px;
					align-items: center;
					display: flex;
					justify-content: flex-end;
				}

				img {
					margin-bottom: 0;
					width: auto;
					
				}

				>ul {

					@include breakpoint(medium, max) {
						display: none;
						opacity: 0;
						transition: 400ms all ease;
					}

					>li {
						font-size: rem(14px);
						line-height: 1.5em;
						text-align: left;
						position: absolute;
						z-index: 2;

						&:nth-of-type(1) {
							top: 4.25rem;
							right: 12.1rem;
						}

						&:nth-of-type(2) {
							top: 7.2rem;
							right: 10.2rem;
						}

						&:nth-of-type(3) {
							top: 10.2rem;
							right: 12.65rem;
						}

						&:nth-of-type(4) {
							display: none;
							top: 12.6rem;
							right: 6.9rem;
						}

						&:nth-of-type(5) {
							// opacity: 0;
							top: 29rem;
							right: 7rem;
						}

						&:nth-of-type(6) {
							top: 35.2rem;
							right: 4.2rem;
						}

						&:nth-of-type(7) {
							top: 34rem;
							right: 11.2rem;

						}

						&:nth-of-type(8) {
							top: 34.35rem;
							right: 15.35rem;
						}

						&:nth-of-type(9) {
							top: 36.7rem;
							right: 13.3rem;

						}

						&:nth-of-type(10) {
							top: 32.5rem;
							right: 17.7rem;

						}

						&:nth-of-type(11) {
							top: 30rem;
							right: 20.4rem;

						}

						&:nth-of-type(12) {
							top: 28.05rem;
							right: 14.3rem;
						}

						&:nth-of-type(13) {
							top: 25.7rem;
							right: 14.55rem;
						}

						&:nth-of-type(14) {
							top: 18.9rem;
							right: 14.3rem;
						}

						&:nth-of-type(15) {
							top: 24.05rem;
							right: 20.05rem;
						}

						&:nth-of-type(16) {
							top: 26rem;
							right: 28.85rem;
						}

						&:nth-of-type(17) {
							top: 25.5rem;
							right: 29.6rem;
						}

						&:before {
							content: '';
							height: 1rem;
							width: 1rem;
							background: transparent;
							display: block;
						}

						&:hover {
							ul {
								display: block;
								opacity: 1;

								&.griechenland {
									position: absolute;
									bottom: 0;
									right: 0;
								}
							}
						}

						ul {
							display: none;
							background: $medium;
							padding: 0.75rem 1rem;
							margin: 0;

							li {
								padding: 0;
								margin: 0;
								font-size: rem(12px);
								line-height: 1.6em;
								position: relative;
								z-index: 111;

								strong {
									font-weight: 700;
								}

								&:before {
									content: none;
								}
							}
						}
					}
				}
			}
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom: rem($baseGap*2);
	}
}

#privacyPolicy {
	a {
		display: inline-block;
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

footer {
	background: $light;


	.footer {
		border-top: 1px solid $border;

		@include breakpoint(small) {
			padding: 0 1rem;
		}

		@include breakpoint(medium) {
			margin: 0 2rem;
			padding: 0 2rem;

		}

		@include breakpoint(large) {
			padding: 3rem 2rem;
			margin: 0 3rem;
		}

		@include breakpoint(giant) {
			margin: 0 1rem;
			padding: 3rem 1rem;
		}

		@include breakpoint(huge) {
			margin: 0 auto 0;
			max-width: rem(1348px);
		}

		img {
			width: auto;
			margin-bottom: 1.5rem;
		}

		.contacts {
			border-bottom: 1px solid $border;
			padding: 4rem 1rem 2.5rem;

			@include breakpoint(large) {
				border-bottom: 0;
				border-right: 1px solid $border;
				padding: 1rem 1rem 0 2rem;
			}

			@include breakpoint(giant) {
				padding: 0rem 1rem 0rem 2rem;
			}

			p {
				font-size: rem(16px);
				line-height: 1.5em;
				margin-bottom: 1.5rem;

				@include breakpoint(tiny) {
					font-size: rem(18px);
					line-height: rem(30px);
				}

				@include breakpoint(large) {
					font-size: rem(16px);
					line-height: 1.6em;
				}

				strong {
					font-size: rem(18px);

					@include breakpoint(tiny) {
						font-size: rem(20px);
					}

				}

				&.info {
					span {
						&.noSpan {
							display: inline-block;
							width: 4rem;

							@include breakpoint(tiny) {
								width: 6rem;
							}

						}
					}
				}

				&.time {
					span {
						display: block;

						@include breakpoint(tiny) {
							display: inline-block;
							width: 10rem;
						}

						@include breakpoint(small) {
							width: 12rem;
						}

						@include breakpoint(large) {
							width: 10rem;
						}

					}
				}
			}
		}
	}

	.naviFoot {
		text-align: text;
		padding: 4rem 1rem;

		@include breakpoint(large) {
			padding: 4rem 1.2rem 4rem 1rem;
		}

		@include breakpoint(giant) {
			padding: 4rem 1.2rem 4rem 5rem;
		}

		li {
			border-bottom: 1px dotted $border;

			@include breakpoint(small) {
				&:last-child {
					border: none;
				}
			}

			a {
				font-size: rem(16px);
				padding: 0.25rem 1rem;
				display: block;
				color: $dark;

				@include breakpoint(large) {
					font-size: rem(14px);
					padding: 0.25rem 0 0.25rem 0.35rem;
				}

				@include breakpoint(huge) {
					font-size: rem(16px);
					padding: 0.35rem 0 0.35rem 0.5rem;
				}

				br {
					display: none;
				}

				&:hover,
				&.active {
					color: $primary;
				}

				&:before {
					content: '»';
					margin-right: 0.5rem;
					display: inline-block;
				}
			}
		}
	}
}

.my-slider-partnerDiv {
	.col {
		padding: 0 0.25rem;

		@include breakpoint(tiny) {
			padding: 0 1rem;
		}

	}
}


body {
	&.index {
		#header {
			@include breakpoint(large) {
				margin-top: 0;
			}

			.my-slider-header {
				height: 20rem;

				@include breakpoint(small) {
					height: 22rem;
				}

				@include breakpoint(medium) {
					height: 26rem;
				}

				@include breakpoint(large) {
					height: 28rem;
				}

				@include breakpoint(giant) {
					height: 31rem;
				}
			}
		}

		main {
			max-width: auto;

			&#content {
				border: none;
				padding: 0 0 2rem 0;
			}
		}

		#navigation {

			@include breakpoint(large) {
				opacity: 0.8;

				&:hover {
					opacity: 1;
				}

				&.fixedNav {
					opacity: 1;
				}
			}
		}
	}

	&.partner,
	&.enPartner {
		.tns-controls {
			padding: 0 1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2rem;

			button {
				border: none;
				height: 35px;
				width: 39px;
				font-size: 0;
				opacity: 0.5;
				transition: 400ms all ease;
				background: $light;

				&:hover {
					opacity: 1;
				}

				&:first-of-type {
					&:before {
						background: url(/images/layout/left.png) no-repeat center;
					}
				}

				&:last-of-type {}

				&:before {
					content: '';
					height: 35px;
					width: 39px;
					display: inline-flex;
					background: url(/images/layout/right.png) no-repeat center;
				}
			}
		}
	}

	&.cmsBackend {

		header,
		footer,
		nav,
		.secondSection {
			display: none;
		}

		.marginBottomBig {
			max-width: 100% !important;
		}
	}

	&.internetExplorer,
	&.edge {
		#header {
			.wrapper {
				video {
					&.wrapper__video {

						@include breakpoint(large) {
							width: 130%;
							min-width: 130%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							min-height: 70vh;
						}

						@include breakpoint(huge) {
							height: 40rem;
							width: 100%;
							min-width: 100%;
							min-height: 57vw;
						}
					}
				}
			}
		}
	}
}

@keyframes mouse {
	from {
		left: 20%;
	}

	to {
		left: 25%;
	}
}

@keyframes mouse2 {
	from {
		left: 50%;
	}

	to {
		left: 55%;
	}
}

@keyframes mouse3 {
	from {
		left: 57%;
	}

	to {
		left: 62%;
	}
}

body:not(.cmsBackend) {
	.customMap {
		text-align: center;
		display: flex;
		vertical-align: center;

		p {
			&.topBack {
				padding-top: 6rem;
			}
		}
	}
}


body.cmsBackend.kontakt {
	.customMap {
		img {
			width: auto;
		}
	}

}

.row {
	&.grid {
		.dcOverviewItem {
			position: relative;

			img {
				margin-bottom: 0;
				display: block;

			}

			&:before {
				content: '';
				height: 47px;
				width: 60px;
				background: url(/images/index/finger.png) no-repeat center;
				display: inline-block;
				position: absolute;
				bottom: 2rem;
				right: -2rem;
				transition: 600ms all ease;
				opacity: 0;
				z-index: 2;
			}

			&:hover {

				&:before {
					bottom: 2rem;
					right: 3rem;
					opacity: 1;
				}
			}
		}

		.highlight {
			&.title {
				position: absolute;
				background: rgba(255, 255, 255, 0.4);
				padding: 1rem 1.5rem;
				display: block;
				width: calc(100% - 2rem);
				z-index: 2;

				a {
					color: $dark;
					display: block;

					&:hover {
						color: $primary;
					}
				}
			}
		}

		a.dcCoverImage {
			display: block;
			position: relative;
			z-index: 1;
		}
	}
}


.dcPagination {
	display: none;
}

.firstTd {
	width: 80%;
}

.secondTd {
	width: 20%;
}

.jobFile>a {
	background-color: #4CAF50;
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;

	&:hover {
		background-color: $secondary;
		color: white;
	}
}

.socialmedia {
	display: flex;
	justify-content: center;
}

.youtube {
	width: 60px !important;
	margin-right: 0.25rem;
}

.linked {
	width: 48px !important;
	margin-left: 0.25rem;
}