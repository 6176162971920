// choose output fromat "images", "text-images", "dropdown" or leave it blank to show default styles
$format: "dropdown";

// width and height only for format "images"
$flagWidth: 28px;
$flagPadding: 2px;

$languagesAmount: 3;

$flagHeight: 18px;
$languages: ('uk','us','at','be','ch','de','es','fr','in','is','it','hr','nl','ru','se','tr','pl','en');

div.languagesWrapper {
    position: relative;
    min-width: $flagWidth + $flagPadding * 2;
    min-height: $flagHeight + $flagPadding * 2;
    
    //Mobil//
    margin-top: rem(20px);
    margin-left: rem(20px);
    display: block;
    width: 100%;
    border-top: 1px solid rgba($dark, 0.6);
    padding-top: rem(20px);
    @include breakpoint(large) {
        margin-top: 0;
        border-top: 0;
        width: auto;
        display: inline-block;
        padding-top: 0;
        min-width: rem(60px);
     }
    
    ul.languages {
        display:flex;
        
        li {
            padding-left: 0;
            margin-bottom: 0;
            &:before {
                display: none;
            }
            a:before {
                border: none;
            }
        }
        
        @if($format == "images") {
            li {
                position: relative;
                text-indent: -99999px;
                overflow: hidden;
                width: $flagWidth + $flagPadding * 2;
                height: $flagHeight + $flagPadding * 2;
                
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    color: transparent;
                }
                
                @each $lang in $languages {
                    &[data-language="#{$lang}"] a:before {
                        background: image-url('/flags/#{$lang}.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        content: "";
                        width: $flagWidth;
                        height: $flagHeight;
                        display: block;
                        border:1px solid $dark;
                    }
                }
            }  
        } @else if($format == "text-images") {
            li {
                @each $lang in $languages {
                    &[data-language="#{$lang}"] a:before {
                        background: image-url('/flags/#{$lang}.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        content: "";
                        width: $flagWidth;
                        height: $flagHeight;
                        display: inline-block;
                        border:1px solid $dark;
                    }
                }


            }
        } @else if($format == "dropdown") {
            @include breakpoint(large) {
                display: flex;
               flex-direction: column;
               max-height: $flagHeight + $flagPadding * 2;
               position: absolute;
               top: 0;
               left: 0;
               transition: max-height 300ms;
            }
            

            select {
                &:before {
                    content:'';
                    height: 10px;
                    width: 10px;
                    display: block;
                }
            }
    
            &:hover {
                max-height: ($flagHeight + $flagPadding * 2) * $languagesAmount;

                li {
                    opacity: 1;
                    visibility: visible;
                }
            }
    
            li {
                display: flex;
                position: relative;
                width: rem(50px);
                height: $flagHeight + $flagPadding * 2;
                transition: all 300ms;
                @include breakpoint(large) {
                    opacity: 0;
                    width: $flagWidth + $flagPadding * 2;
                    visibility: hidden;
                }
                
                
                &.active {
                    order:-1;
                    z-index: 10;
                    position: relative;
                    opacity: 1;
                    visibility: visible;
    
                    a {
                        pointer-events: none;
                    }
                    &:after {
                        @include breakpoint(large) {                        
                            content: "\2B9F";
                            margin-left: rem(10px);
                            vertical-align: middle;
                            cursor: pointer;
   
                        }   
                    }
                }
                
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    padding: $flagPadding;
                    color: transparent;
                }
                
                @each $lang in $languages {
                    &.#{$lang} a:before,
                    &[data-language="#{$lang}"] a:before {
                        background: image-url('/flags/#{$lang}.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                        content: "";
                        width: $flagWidth;
                        height: $flagHeight;
                        display: inline-block;
                        border:1px solid rgba($dark, .6);
                    }
                }
            }
        }
    }
}
