// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

// Example font-face for Roboto
// @include fontFaceV2(
//     $fontName: "Roboto", 
//     $fileName: "roboto-v19-latin", 
//     $weights: ("400", "700", "900", "900|italic"), 
//     $types: all
// );