.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $primary;
	font-weight: 700;
	text-transform: uppercase;

	&.green{
		display: block;
		background: $primary;
		color: $light;
		padding: 0.5rem;
		text-align: center;
		
	}
}

.centerDiv{
	text-align: center;
}

.marginBottomBig{
	margin-bottom: 2rem;
}

.pdfDiv{
	a{
		color: $light;
		background: $dark;
		text-align: center;
		display: block;
		padding: 0.5rem;
		font-size: rem(16px);

		&:hover{
			background: $primary;
			color: $light;
		}
	}
}

body{
	&.partner{
		.loopBackground{ 
			background: $medium; 
			padding:2rem; 
			margin-bottom: 0;

			p{
				margin-bottom: 0;
			}
		}

		.boderDivMed{
			border: 1px solid $medium;
		}
	}
}


.loopBackground{ 
	background: $medium; 
	padding:2rem; 
	margin-bottom:2rem;
}

.middle{
	align-items: center;
}

.btn {
	@extend %animatedTransform;
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: block;
	line-height: rem($baseLineHeight);
	padding: 0.8rem 1rem;
	font-size: rem(16px);
	text-align: center;
	text-decoration: none;
	font-family: $mainFont;
	
	@include breakpoint(small) {
		padding: 0.8rem 2rem;
		display: inline-block;
	}
	
	&:hover,
	&:active,
	&:focus {
		background: $secondary;
		color: $light;
	}

	&.border{
		background: $secondary;
		border: 1px solid $secondary;

		&:hover{
			background: transparent;
			border: 1px solid $light;
		}
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

.notWide{
	display: inline-block;
}

/* 16:9 video resolutions */
.videoContainer {

	
	&:not(.videoTag)
	{
		position: relative;
		background-image:url('../images/layout/placeholder-video.jpg');
		background-size:cover;
		background-position: center center;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
	
		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top:0; 
			left:0; 
		}
	}

	video {
		width:100%;
		height:auto;
	}
}
.googleMaps {
	@extend .videoContainer;
	background-image:url('../images/layout/placeholder-maps.jpg')!important;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 999999;
  visibility: hidden;
  display: block;
  
  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:first-child):not(.hide).transitionIn {
    margin-top: $baseGap;
  }

  .notification {
    display: block;
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding:0;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    visibility: hidden;
    opacity: 0;
    max-height:none;
    height: 0;
    box-sizing:border-box;
    margin-left:-100px;

    .noteContent {
      padding:1rem;
      display: block;
    }

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &.transitionIn {
      visibility: visible;
      opacity: 1;
      max-height:9999999px;
      height: auto;
      margin-left:0;
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;
      
      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      margin-left:-100px;
      opacity: 0;
      visibility: hidden;
      height:0;
    }
  }
}

.modalGallery{
	&.isOnScreen{
		img{
			background:$light;
		}
	}
}

// collapser
// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 0.5rem;
$collapser-color: $secondary; 
$collapser-dark: $dark; 

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;
        margin-bottom: rem(10px);
        color: $light;
        font-size: 16px;
        transition: 400ms all ease;

        &.active,
        &:hover{
        	background: $primary;
        }

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $light;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
            @include breakpoint(medium, max) {
            	width: 13px;
            }
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {

}

//p.collapser{
	//display: none;
//}

.borderDiv{
	border-right: 1px solid $border;
	border-left: 1px solid $border;
	border-bottom: 1px solid $border;
	
	p{
		padding:$baseGap;
	}
}


.spacerTop{
	margin-top:rem(60px);
}

// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $dark;
$collapser-dark: $light;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb 78px $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {

}