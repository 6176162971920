/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 38px;

$h2Size: 32px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: Arial, sans-serif;

$displayFont: Arial, sans-serif;

$iconFont: sans-serif;

// Project colors

$primary: #009b4a;
$secondary: #262626;
$light: #fff;
$medium: #f2f2f2;
$dark: #505050;
$border: #cacaca;
$alert: #9d9d9d;
$background: #e6e6e3;
$extra: #5ae09e;

$baseGap: 18px;

$rowMaxWidth: 1604px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	angle-down: ""107"", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (4, 8),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (4, 6, 8, 12),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (4, 5, 6, 7, 8, 12),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (6),
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

