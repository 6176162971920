// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-size: rem(16px);
	text-transform: uppercase;

	@include breakpoint(large) {
		padding: 0;
		text-transform: none;
	}
	
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint(large) {
		background: transparent;
		color: $primary;
	}
	
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;

	@include breakpoint(large) {
		background: transparent;
		color: $primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 60px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $primary; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {

		@include breakpoint(large) {
			max-width: rem(1380px);
			margin: 0 auto;
			padding: 0 1rem;
		}
		
		@include ul {
			&.navi {
				padding-bottom: rem(110px);

				@include breakpoint(large) {
					padding-bottom: 0;
				}
				
				li {
					border-bottom: 1px solid $border;
					
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 0.5em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}

					a{
						br{
							display: none;
						}
					}
				}
			}
		}

		.naviImp{
			.navi{
				&.mainBar{
					width: auto;
					
					@include breakpoint(large,max) {
						display: none;
					}

					li{
						line-height: 1em;
						text-align: center;
						margin-top: 0.9rem;
						
						@include breakpoint(giant) {
							margin-top: 1.1rem;
						}
						
						@include breakpoint(huge) {
							line-height: 1.1em;
						    margin-top: 0.9rem;
						}
						
						a{
							text-transform: uppercase;
							font-size: rem(12px);
							font-weight: 600;
							
							@include breakpoint(huge) {
								font-size: rem(14px);
							}
							
							&:before{
								content: '';
								height: 60px;
								width: 60px;
								display: block;
								position: relative;
								background: $medium;
								opacity: 0.4;
								margin: 0 auto 0.5rem;
								transition: 400ms all ease;
							}

							&:hover,
							&.active{
								&:before{
									opacity: 1;
								}
							}

							br{
								@include breakpoint(large) {
									display: block;
								}
							}
						}
						
						&.schneckengetriebe, &.wormGearUnits{
							a{
								&:before{
									background: url(/images/layout/schnecken.png) no-repeat center;
								}
							}
						}

						&.kegelradgetriebe, &.bevelGears{
							a{
								&:before{
									background: url(/images/layout/kegelrad.png) no-repeat center;
								}
							}
						}

						&.planetengetriebe, &.planetaryGear{
							a{
								&:before{
									background: url(/images/layout/planeten.png) no-repeat center;
								}
							}
						}

						&.robotergetriebe, &.robotGear{
							a{
								&:before{
									background: url(/images/layout/roboter.png) no-repeat center;
								}
							}
						}

						&.getriebesysteme, &.transmissionSystems{
							a{
								&:before{
									background: url(/images/layout/getriebesystem.png) no-repeat center;
								}
							}
						}

						&.zahnstangeritzel, &.rackPinion{
							a{
								&:before{
									background: url(/images/layout/zahnstange.png) no-repeat center;
								}
							}
						}

						&.schmiersystem, &.lubricationSystem{
							a{
								&:before{
									background: url(/images/layout/schmiersystem.png) no-repeat center;
								}
							}
						}

						&.welleNabeVerbindungen, &.shaftHubConnections{
							a{
								&:before{
									background: url(/images/layout/welle-nabe.png) no-repeat center;
								}
							}
						}

						&.direktantriebe, &.directDrives{
							a{
								&:before{
									background: url(/images/layout/direktantrieb.png) no-repeat center;
								}
							}
						}
					}
					
				}
			}
		}

		.contacts{
			width: 42%;
			display: inline-block;
			text-align: right;
			
			@include breakpoint(huge) {
				width: 50%;
			}
			
			p{
				font-size: rem(14px);
				display: inline-block;
				
				@include breakpoint(giant) {
					font-size: rem(16px);
				}
				
				&:before{
					content: '';
					display: inline-block;
					margin-right: 0.5rem;
					position: relative;
					top: 0.25rem;
				}

				&.phone{
					&:before{
						background: url(/images/layout/phone.png) no-repeat center;
						height: 22px;
						width: 22px;
					}
				}

				&.mail{
					margin-left: 1rem;
					
					@include breakpoint(huge) {
						margin-left: 2.5rem;
					}
					
					a{
						text-decoration: underline;
						color: $dark;

						&:hover{
							color: $primary;
						}
					}
					&:before{
						background: url(/images/layout/mail.png) no-repeat center;
						height: 18px;
						width: 24px;
					}
				}

			
			}

			@include breakpoint(large,max) {
				border-bottom: 0;
				p{
					display: none;
				}
			}
			
		}
	}

	.borderBottom{

		@include breakpoint(large) {
			padding: 0.7rem 0;
			border-bottom: 1px solid $primary;
		}
		@include breakpoint(large,max) {
			display: flex;
			flex-direction: column;
			.contacts{
				order: -1;
				.languagesWrapper{
					margin-top: 0;
					margin-left:0;
					padding: 0;
					border: 0;
					width: 100%;
					min-width: 10rem;
					.languagesWrapper{
						padding: $baseGap;

					}
				}
			}

			p{
				display: none;
			}
		}
		
	}

}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		transition: 400ms all ease;
		
		@include breakpoint(large) {
			opacity: 1;
		}
		
		@include breakpoint(huge) {
		   	max-width: 1604px;
		   // margin: 0 auto;
		    transform: translateX(-50%);
		    left: 50%;
		    z-index: 3;

		}

		
		
		&.fixedNav{
			box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.1);
			max-width: 100%;
			opacity: 1;
		}

		nav {

			ul {
				&.navi {
					&.topBar{
						width: 58%;
						display: inline-flex;
						
						@include breakpoint(huge) {
							width: 50%;
						}
						
						.kegelradgetriebe, .bevelGears,
						.planetengetriebe, .planetaryGear,
						.robotergetriebe, .robotGear, 
						.getriebesysteme, .transmissionSystems,
						.zahnstangeritzel, .rackPinion,
						.schmiersystem, .lubricationSystem,
						.welleNabeVerbindungen, .shaftHubConnections,
						.schneckengetriebe, .wormSystems
						.direktantriebe, .directDrive{
							display: none;
						}
					}

					li {
						border-bottom: none;

						a{
							font-size: rem(14px);

							@include breakpoint(giant) {
								font-size: rem(16px);
							}
							
						}
					}

					&.mainBar{
						position: relative;

						@include breakpoint(giant) {
							padding-right: 5rem;

							&:before{
								content: '';
								height: 55px;
								width: 61px;
								display: inline-block;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								background: url(/images/layout/g_brand.png) no-repeat center;
								margin-top: 0.5rem;
							}
						}
						
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $light;
	color: $dark;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	border-bottom: 1px solid $primary;
	font-size: rem(16px);

	@include breakpoint($break) {
		display: none;
	}

	span{
		&:before{
			content: '';
			height: 22px;
			width: 22px;
			display: inline-block;
			margin-right: 0.5rem;
			background: url(/images/layout/phone.png) no-repeat center;
			position: relative;
			top: 0.25rem;
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////